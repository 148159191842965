<template>
<div class="user-page">
    <UserPad />

    <!-- <div class="nav-btn-wrp">
    <div class="nav-title">我的订单</div>
    <div class="nav-btns">
      <router-link to="/insuranceList/0">
        <img class="img-nav-btns" src="../assets/user_icon01.png" alt="nav icon">
        <p>全部</p>
      </router-link>
      <router-link to="/insuranceList/1">
        <img class="img-nav-btns" src="../assets/user_icon02.png" alt="nav icon">
        <p>有效订单</p>
      </router-link>
      <router-link to="/insuranceList/3">
        <img class="img-nav-btns" src="../assets/user_icon03.png" alt="nav icon">
        <p>理赔/售后</p>
      </router-link>
      <router-link to="/insuranceList/2">
        <img class="img-nav-btns" src="../assets/user_icon04.png" alt="nav icon">
        <p>已过期</p>
      </router-link>
    </div>
  </div> -->

    <!-- <div class="nav-btn-wrp">
        <div class="nav-title">医疗保险报销理赔</div>
        <div class="nav-btns">
            <router-link to="/insuranceApplyList/0">
                <img class="img-nav-btns" src="../assets/user_icon11.png" alt="nav icon">
                <p>待提交</p>
            </router-link>
            <router-link to="/insuranceApplyList/1">
                <img class="img-nav-btns" src="../assets/user_icon12.png" alt="nav icon">
                <p>有效订单</p>
            </router-link>
            <router-link to="/insuranceApplyList/2">
                <img class="img-nav-btns" src="../assets/user_icon13.png" alt="nav icon">
                <p>待评价</p>
            </router-link>
            <router-link to="/insuranceApplyList/3">
                <img class="img-nav-btns" src="../assets/user_icon14.png" alt="nav icon">
                <p>已提交</p>
            </router-link>
        </div>
    </div> -->

    <div class="nav-btn-wrp">
        <div class="nav-title">活动订单</div>
        <div class="nav-btns">
            <router-link to="/markOrderList/1">
                <img class="img-nav-btns" src="../assets/user_icon21.png" alt="nav icon">
                <p>待付款</p>
            </router-link>
            <router-link to="/markOrderList/2">
                <img class="img-nav-btns" src="../assets/user_icon22.png" alt="nav icon">
                <p>待发货</p>
            </router-link>
            <router-link to="/markOrderList/3">
                <img class="img-nav-btns" src="../assets/user_icon23.png" alt="nav icon">
                <p>待收货</p>
            </router-link>
            <router-link to="/markOrderList/4">
                <img class="img-nav-btns" src="../assets/user_icon24.png" alt="nav icon">
                <p>待评价</p>
            </router-link>
        </div>
    </div>

    <div class="nav-btn-wrp">
        <div class="nav-title">商场订单</div>
        <div class="nav-btns">
            <router-link to="/emporiumOrderList/1">
                <img class="img-nav-btns" src="../assets/user_icon21.png" alt="nav icon">
                <p>待付款</p>
            </router-link>
            <router-link to="/emporiumOrderList/2">
                <img class="img-nav-btns" src="../assets/user_icon22.png" alt="nav icon">
                <p>待发货</p>
            </router-link>
            <router-link to="/emporiumOrderList/3">
                <img class="img-nav-btns" src="../assets/user_icon23.png" alt="nav icon">
                <p>待收货</p>
            </router-link>
            <router-link to="/emporiumOrderList/4">
                <img class="img-nav-btns" src="../assets/user_icon24.png" alt="nav icon">
                <p>待评价</p>
            </router-link>
        </div>
    </div>

    <NavBottom :checkNum="5" />

</div>
</template>

<script>
export default {
    data() {
        return {};
    }
};
</script>

<style scoped>
.user-page {
    text-align: center;
    background: url("../assets/user_top_bg.png") no-repeat center top / 100% auto;

}

.top-wrp {
    color: #fff;
    position: relative;
    padding: 5px;
    padding-top: 20px;
    height: 23vw;
}

.top-sign-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    padding: 0 12px;
    position: absolute;
    right: 0;
    top: 21vw;
    border-radius: 10px 0 0 10px;
    background-image: linear-gradient(to right, #ffa94c, #ffd4a5)
}

.top-sign-icon::after,
.user-discount-icon::after {
    content: "";
    height: 5px;
    width: 5px;
    position: absolute;
    top: 7px;
    right: 5px;
    border: 1px solid transparent;
    border-right-color: #fff;
    border-bottom-color: #fff;
    transform: rotateZ(-45deg);
}

.stumb-icon {
    display: inline-block;
    height: 22px;
    width: 10px;
    margin-right: 3px;
    background: url("../assets/thumb.png") no-repeat center center / 100% auto;

}

.star-icon {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: 3px;
    background: url("../assets/star.png") no-repeat center center / 100% auto;
}

.user-discount-icon::after {
    top: 4px;
}

.user-discount-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    padding-right: 12px;
    position: absolute;
    right: 0;
    top: 4px;
    background-image: linear-gradient(to right, #57575c, #363b3f);
    border-radius: 10px;

}

.top-set-icon {
    display: block;
    width: 22px;
    height: 22px;
    background: url("../assets/setIcon.png") no-repeat center center / 100% auto;
    position: absolute;
    top: 6px;
    right: 6px;
}

.top-head {
    margin-left: 10px;
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.user-info {
    width: calc(100% - 16vw - 6px);
    position: relative;
}

.user-info p {
    line-height: 1.6
}

.user-name {
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 70px);
}

.user-info p:last-child {
    font-size: 13px;
}

.avatar-wrap {
    margin-right: 6px;
    position: relative;
    width: 16vw;
    height: 16vw;
    border-radius: 50%;
    overflow: hidden;
}

.avatar-wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.nav-btn-wrp {
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff;
    margin: 10px 5px;
    margin-bottom: 16px;
    box-shadow: 0 0 2px #eee;
}

.nav-title {
    padding: 5px 12px;
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #ccc;

}

.nav-btns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 12px 5px;
}

.img-nav-btns {
    height: 25px;
    padding: 5px 0;
}

.nav-btns a {
    display: inline-block;
    width: 20%;
}

.nav-btns p {
    font-size: 14px;
    color: #373c40
}
</style>
